<template>
  <div>
    <el-table ref="multiTable" :data="podcForm.podc_part_list" :header-cell-style="headerCellStyle" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="60" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="产品类型" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.podr_category'" label-width="0">
            <el-select v-model="scope.row.podr_category" :disabled="podcDisableForm.podr_category" filterable placeholder="请选择产品类型">
              <el-option
                v-for="item in [
                  { value: '新款', label: '新款' },
                  { value: '翻单', label: '翻单' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="原合同编号" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.podr_no'" label-width="0">
            <el-input v-model="scope.row.podr_no" :disabled="podcDisableForm.podr_no" maxlength="20" placeholder="暂无原合同编号" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="新合同编号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.new_podr_no'">
            <el-input
              v-model="scope.row.new_podr_no"
              :disabled="podcDisableForm.new_podr_no"
              maxlength="20"
              show-word-limit
              placeholder="暂无新合同编号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="我司货号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_no'">
            <el-input v-model="scope.row.prod_no" :disabled="podcDisableForm.prod_no" maxlength="20" placeholder="暂无我司货号" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="货号后缀" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_poststfix'">
            <el-input
              v-model="scope.row.prod_poststfix"
              :disabled="podcDisableForm.prod_poststfix"
              maxlength="20"
              show-word-limit
              placeholder="暂无货号后缀"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="部件名称" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_partno'">
            <el-input
              v-model="scope.row.prod_partno"
              :disabled="podcDisableForm.prod_partno"
              maxlength="20"
              placeholder="暂无部件名称"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_cust_no'">
            <el-input
              v-model="scope.row.prod_cust_no"
              :disabled="podcDisableForm.prod_cust_no"
              maxlength="20"
              show-word-limit
              placeholder="暂无客户货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品描述" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_desc'">
            <el-input v-model="scope.row.prod_desc" :disabled="podcDisableForm.prod_desc" placeholder="暂无产品描述" show-word-limit>
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(0, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="podcDisableForm.prod_desc"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column label="中文规格" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_spec'">
            <el-input v-model="scope.row.prod_spec" :disabled="podcDisableForm.prod_spec" maxlength="100" show-word-limit placeholder="暂无"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '规格'" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.podc_spec'">
            <el-input
              :disabled="podcDisableForm.podc_spec"
              @change="changeConent"
              type="textarea"
              :rows="2"
              v-model="scope.row.podc_spec"
              maxlength="100"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>-->
      <el-table-column label="原合同数量" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.podr_part_num'">
            <el-input
              v-model="scope.row.podr_part_num"
              :disabled="podcDisableForm.podr_part_num"
              maxlength="20"
              show-word-limit
              placeholder="暂无原合同数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '数量'" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.podc_num'" :rules="[{ required: type2 }]" label-width="0">
            <el-input
              v-model="scope.row.podc_num"
              :disabled="podcDisableForm.podc_num"
              @change="calcProdPrice(scope.$index)"
              @input="scope.row.podc_num < 1 ? (scope.row.podc_num = 1) : (scope.row.podc_num = keep2Decimal(scope.row.podc_num))"
              maxlength="10"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量单位" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_unit'">
            <el-select v-model="scope.row.prod_unit" :disabled="podcDisableForm.prod_unit" filterable placeholder="请选择">
              <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="原合同单价" width="200">
        <template v-slot="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'podc_part_list.' + scope.$index + '.prod_price'" label-width="0">
            <el-input
              v-model="scope.row.prod_price"
              :disabled="podcDisableForm.prod_price"
              maxlength="20"
              show-word-limit
              placeholder="暂无原合同单价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '单价'" width="200">
        <template v-slot="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'podc_part_list.' + scope.$index + '.podc_price'" :rules="[{ required: type2 }]" label-width="0">
            <el-input
              v-model="scope.row.podc_price"
              :disabled="podcDisableForm.podc_price"
              @change="calcProdPrice(scope.$index)"
              @input="val => (scope.row.podc_price = keep4Decimal(val))"
              maxlength="9"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="原合同金额" width="200">
        <template v-slot="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'podc_part_list.' + scope.$index + '.podc_total'" label-width="0">
            <el-input
              v-model="scope.row.podc_total"
              :disabled="podcDisableForm.podc_total"
              maxlength="30"
              show-word-limit
              placeholder="暂无原合同金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '合同金额'" width="200">
        <template v-slot="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'podc_part_list.' + scope.$index + '.fact_podc_total'" :rules="[{ required: type2 }]" label-width="0">
            <el-input v-model="scope.row.fact_podc_total" :disabled="podcDisableForm.fact_podc_total" maxlength="10" placeholder="" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="内包装装量" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_qpb'">
            <el-input v-model="scope.row.prod_qpb" :disabled="podcDisableForm.prod_qpb" maxlength="10" placeholder="暂无内包装装量" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '内包装装量'" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.podc_qpb'" :rules="[{ required: type2 }]" label-width="0">
            <el-input
              v-model="scope.row.podc_qpb"
              @change="changeConent"
              :disabled="podcDisableForm.podc_qpb"
              @input="scope.row.podc_qpb = helper.keepTNum(scope.row.podc_qpb)"
              maxlength="10"
              show-word-limit
              placeholder="暂无更改后内包装装量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外箱装量" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_qpc'">
            <el-input v-model="scope.row.prod_qpc" :disabled="podcDisableForm.prod_qpc" maxlength="20" placeholder="暂无外箱装量" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '外箱装量'" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.podc_qpc'" :rules="[{ required: type2 }]" label-width="0">
            <el-input
              v-model="scope.row.podc_qpc"
              :disabled="podcDisableForm.podc_qpc"
              @change="calcBoxNum(scope.$index)"
              @input="scope.row.podc_qpc = helper.keepTNum(scope.row.podc_qpc)"
              maxlength="10"
              show-word-limit
              placeholder="暂无更改后外箱装量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="箱数" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_box_num'">
            <el-input
              v-model="scope.row.prod_box_num"
              :disabled="podcDisableForm.prod_box_num"
              maxlength="10"
              placeholder="暂无箱数"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '箱数'" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.new_prod_box_num'" :rules="[{ required: type2 }]" label-width="0">
            <el-input
              v-model="scope.row.new_prod_box_num"
              :disabled="podcDisableForm.new_prod_box_num"
              maxlength="10"
              show-word-limit
              placeholder="暂无箱数"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="长" width="100">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.prod_boxl'" label-width="0">
            <el-input
              v-model="scope.row.prod_boxl"
              :disabled="podcDisableForm.prod_boxl"
              maxlength="7"
              placeholder="暂无"
              show-word-limit
              @input="val => (scope.row.prod_boxl = keep2Decimal(val))"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="宽" width="100">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.prod_boxw'" label-width="0">
            <el-input
              v-model="scope.row.prod_boxw"
              :disabled="podcDisableForm.prod_boxw"
              maxlength="7"
              placeholder="暂无"
              show-word-limit
              @input="val => (scope.row.prod_boxw = keep2Decimal(val))"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="高" width="100">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.prod_boxh'" label-width="0">
            <el-input
              v-model="scope.row.prod_boxh"
              :disabled="podcDisableForm.prod_boxh"
              maxlength="7"
              placeholder="暂无"
              show-word-limit
              @input="val => (scope.row.prod_boxh = keep2Decimal(val))"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外箱毛重" width="100">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.total_gross_weight'" label-width="0">
            <el-input
              v-model="scope.row.total_gross_weight"
              :disabled="podcDisableForm.total_gross_weight"
              maxlength="7"
              placeholder="暂无"
              show-word-limit
              @input="val => (scope.row.total_gross_weight = keep2Decimal(val))"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外箱净重" width="100">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.total_net_weight'" label-width="0">
            <el-input
              v-model="scope.row.total_net_weight"
              :disabled="podcDisableForm.total_net_weight"
              maxlength="7"
              placeholder="暂无"
              show-word-limit
              @input="val => (scope.row.total_net_weight = keep2Decimal(val))"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外包装单位" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_box_unit'">
            <el-input
              v-model="scope.row.prod_box_unit"
              :disabled="podcDisableForm.prod_box_unit"
              maxlength="20"
              show-word-limit
              placeholder="暂无外包装单位"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '外包装单位'" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.podc_box_unit'" :rules="[{ required: type2 }]" label-width="0">
            <el-select
              v-model="scope.row.podc_box_unit"
              @change="changeConent"
              filterable
              :disabled="podcDisableForm.podc_box_unit"
              placeholder="请选择更改后外包装单位"
            >
              <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="交货期" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.podr_part_pedate'">
            <el-date-picker v-model="scope.row.podr_part_pedate" :disabled="podcDisableForm.podr_part_pedate" placeholder="选择日期" type="date">
            </el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '交货日期'" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.new_podr_part_pedate'" :rules="[{ required: type2 }]" label-width="0">
            <el-date-picker
              v-model="scope.row.new_podr_part_pedate"
              :disabled="podcDisableForm.new_podr_part_pedate"
              value-format="timestamp"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="收货工厂" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.supp_bid'">
            <el-select v-model="scope.row.supp_bid" :disabled="podcDisableForm.supp_bid" placeholder="请选择供应商简称">
              <el-option v-for="item in suppList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '收货工厂'" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.new_supp_bid'" :rules="[{ required: type2 }]" label-width="0">
            <el-select v-model="scope.row.new_supp_bid" :disabled="podcDisableForm.new_supp_bid" filterable placeholder="请选择供应商简称">
              <el-option v-for="item in suppList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品条形码" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_bar'">
            <el-input v-model="scope.row.prod_bar" :disabled="podcDisableForm.prod_bar" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '产品条形码'" width="200">
        <template slot="header">
          <span style="color: red">{{ type56 + '产品条形码' }}</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.new_prod_bar'" label-width="0">
            <el-input v-model="scope.row.new_prod_bar" :disabled="podcDisableForm.new_prod_bar" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="内包装条形码" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_inbar'">
            <el-input v-model="scope.row.prod_inbar" :disabled="podcDisableForm.prod_inbar" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '内包装条形码'" width="200">
        <template slot="header">
          <span style="color: red">{{ type56 + '内包装条形码' }}</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.new_prod_inbar'" label-width="0">
            <el-input v-model="scope.row.new_prod_inbar" :disabled="podcDisableForm.new_prod_inbar" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外包装条形码" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'podc_part_list.' + scope.$index + '.prod_outbar'">
            <el-input v-model="scope.row.prod_outbar" :disabled="podcDisableForm.prod_outbar" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="type56 + '外包装条形码'" width="200">
        <template slot="header">
          <span style="color: red">{{ type56 + '外包装条形码' }}</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'podc_part_list.' + scope.$index + '.new_prod_outbar'" label-width="0">
            <el-input v-model="scope.row.new_prod_outbar" :disabled="podcDisableForm.new_prod_outbar" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { keep2Decimal, keep4Decimal, keep6Decimal } from '@assets/js/regExUtil';

export default {
  name: 'ProdDetail',
  props: {
    podcForm: {
      type: Object,
      required: true
    },
    podcDisableForm: {
      type: Object,
      required: true
    },
    suppList: {
      type: Array
    },
    isShow: {
      type: Boolean
    },
    isQCDepartment: {
      type: Boolean
    }
  },
  computed: {
    type56() {
      return [3, 4].includes(this.podcForm.podc_type) ? '新厂' : this.podcForm.podc_type === 6 ? '库存' : '更改后';
    },
    type2() {
      return this.podcForm.podc_type === 2;
    }
  },
  data() {
    return {
      multipleSelection: [],
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      prodUnitList: []
    };
  },
  components: {
    textEnlargement
  },
  created() {
    this.initData();
  },
  methods: {
    keep4Decimal,
    keep2Decimal,
    headerCellStyle({ column }) {
      if (this.type2 && String(column.label).indexOf('更改后') !== -1) return { color: 'red' };
    },
    keep6Decimal,
    initData() {
      this.getProdRankList();
    },
    // 选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('SelectChnage', this.multipleSelection);
    },
    // 变化内容
    changeConent() {
      setTimeout(() => {
        this.$emit('changeConent');
      }, 300);
    },
    // 计算箱数
    calcBoxNum(val) {
      if (Number(this.podcForm.podc_part_list[val].podc_num) % Number(this.podcForm.podc_part_list[val].podc_qpc) !== 0) {
        this.$message.warning('箱数未能整除，已向上取整');
      }
      let prodTgweight = new BigNumber(1);
      prodTgweight = prodTgweight
        .times(this.podcForm.podc_part_list[val].podc_num ? this.podcForm.podc_part_list[val].podc_num : 0)
        .div(this.podcForm.podc_part_list[val].podc_qpc ? this.podcForm.podc_part_list[val].podc_qpc : 0);
      if (prodTgweight.isFinite()) {
        this.$set(this.podcForm.podc_part_list[val], 'new_prod_box_num', prodTgweight.toFixed(0, 0));
      }
      this.changeConent();
    },
    // 计算金额
    calcProdPrice(val) {
      // this.podcForm.podc_part_list[val].podc_num = new BigNumber(this.podcForm.podc_part_list[val].podc_num).toFixed(2, 0);
      let prodTgweight = new BigNumber(1);
      prodTgweight = prodTgweight
        .times(this.podcForm.podc_part_list[val].podc_num ? this.podcForm.podc_part_list[val].podc_num : 0)
        .times(this.podcForm.podc_part_list[val].podc_price ? this.podcForm.podc_part_list[val].podc_price : 0);
      prodTgweight = prodTgweight.toFixed(4);
      this.$set(this.podcForm.podc_part_list[val], 'fact_podc_total', prodTgweight);
      this.calcBoxNum(val);
    },

    // 获取单位
    getProdRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodUnitList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.podcForm.podc_part_list[val1].prod_desc;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.podcForm.podc_part_list[this.textEnlargementNum].prod_desc = val;
      }
    },
    // // 计算价格保留四位
    calcPrice(num, posi = 0, str) {
      let num1 = 0;
      let fre = null;
      if (posi % 3 === 1) {
        fre = Math.pow(10, posi + 1);
      } else {
        fre = Math.pow(10, posi);
      }
      num1 = (num * fre) / fre;
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.0001;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.0000';
            } else {
              if (value.length - 1 - ind > 4) {
                return value.substr(0, ind + 5);
              } else if (value.length - 1 - ind < 4) {
                let index = 4 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    // 计算价格保留2位
    calcPriceT(num, posi = 0, str) {
      let num1 = 0;
      num1 = Number(num);
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.01;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.00';
            } else {
              if (value.length - 1 - ind > 2) {
                return value.substr(0, ind + 3);
              } else if (value.length - 1 - ind < 2) {
                let index = 2 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
